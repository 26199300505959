import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import PlaceSearch from "./PlaceSearch";

export default async function storeLocator() {
    /**
     * STORE LOCATOR.
     * ****************************************
     */
    const StoreLocatorMap = document.getElementById(
        "store-locator-map"
    ) as HTMLElement;
    if (StoreLocatorMap) {
        const markers = [] as any;
        const bounds = new google.maps.LatLngBounds();

        // Create map.
        const map = createMap();

        // Get stores.
        let stores: [] = window.drupalSettings.store_locator_list;

        if (stores.length > 0) {
            // Create markers.
            createMarkers(map, markers, bounds, stores);

            // Create user marker.
            createUserMarker(map, markers, bounds);

            if (markers.length > 0) {
                map.setCenter(markers[0].getPosition());
            }

            // Add event listeners for when the map has changed: drag, zoom in/out or page refreshed.
            google.maps.event.addListener(map, "dragend", function () {
                markersInViewport(map, markers);
            });
            google.maps.event.addListener(map, "idle", function () {
                markersInViewport(map, markers);
            });
        }
    }

    /*
     * Get markers visible in the viewport for the render card.
     */
    function markersInViewport(map: any, markers: any) {
        // Clear list.
        clearCardsList();

        renderCards(markers);

        // List appearance.
        const has_filters = document.querySelector(
            `form[data-filters]`
        ) as any;

        if (markers.length != 0 && has_filters.dataset.filters != '') {
            const storeLocatorMapMessage = document.querySelector(
                ".store-locator__message-location"
            ) as HTMLElement;
            if (storeLocatorMapMessage != null)
                storeLocatorMapMessage.remove();

            const storeLocatorContainer = document.querySelector(
                ".store-locator__container"
            ) as HTMLElement;
            storeLocatorContainer.classList.add("active");
        }

        if (markers.length === 0) {
            const storeLocatorContainer = document.querySelector(
                ".store-locator__container"
            ) as HTMLElement;
            storeLocatorContainer.classList.remove("active");
        }

        if (
            markers.length === 1 &&
            map.getCenter() !== markers[0].getPosition()
        ) {
            map.setCenter(markers[0].getPosition());
        }

        // Manage the card list header.
        manageCardListHeader(markers);
    }

    /*
     * Render cards.
     */
    function renderCards(markers: any) {
        const storeLocatorContainer = document.querySelector(
            ".store-locator__list .views-content"
        ) as HTMLElement;

        let type = document.querySelectorAll(
            '.filters--store-locator input[name="type[]"]'
        ) as NodeListOf<Element>;

        // Appointment url;
        let appointmentUrl: string = getAppointmentUrl();

        // Create cards.
        const templateArtisan = document.querySelector(
            ".store-locator__list .template-artisan .type-card"
        ) as HTMLElement;
        markers.forEach((marker: any) => {
            if (marker?.store != undefined) {

                const storeRendered = templateArtisan.cloneNode(
                    true
                ) as HTMLElement;

                // Define class for the type of card.
                const type_class =
                    marker?.store?.bundle == "artisan"
                        ? "type-artisan"
                        : "type-exhibition_hall";
                storeRendered.classList.add(type_class);

                storeRendered.dataset.id = marker?.store?.id;

                // Add title.
                const storeRenderedTitle = storeRendered.querySelector(
                    ".heading--4"
                ) as HTMLElement;
                storeRenderedTitle.innerHTML = marker?.store?.title;

                // Add jobs list.
                const storeRenderedJobs = storeRendered.querySelector(
                    ".type__jobs"
                ) as HTMLElement;
                const jobs = marker?.store?.jobs;
                jobs.forEach((job: any) => {
                    const li = document.createElement("li") as HTMLElement;
                    li.innerHTML = job;
                    storeRenderedJobs.append(li);
                });

                // Add address.
                const storeRenderedAdress = storeRendered.querySelector(
                    ".type__adress div"
                ) as HTMLElement;
                const p1 = document.createElement("p") as HTMLElement;
                p1.innerHTML = marker?.store?.address;
                storeRenderedAdress.append(p1);
                const p2 = document.createElement("p") as HTMLElement;
                p2.innerHTML =
                    marker?.store?.postal_code + " " + marker?.store?.city;
                storeRenderedAdress.append(p2);

                // Add description.
                const storeRenderedDescription = storeRendered.querySelector(
                    ".card__desc"
                ) as HTMLElement;
                if (marker?.store?.description !== false) {
                    storeRenderedDescription.innerHTML =
                        marker?.store?.description.length > 80
                            ? `${marker?.store?.description.substring(
                                  0,
                                  80
                              )}...`
                            : marker?.store?.description;
                }

                // Add project type list.
                const storeRenderedProjectType = storeRendered.querySelector(
                    ".type__project-type ul"
                ) as HTMLElement;
                const storeRenderedProjectTypeList =
                    document.createElement("ul");
                storeRenderedProjectType?.appendChild(
                    storeRenderedProjectTypeList
                );
                if (
                    storeRenderedProjectType != null &&
                    marker?.store?.bundle == "artisan"
                ) {
                    const projectTypeList = (marker?.store?.project_type).split(
                        ","
                    ) as string[];
                    if (projectTypeList[0] !== "") {
                        projectTypeList.forEach((projectTypeItem) => {
                            storeRenderedProjectType.innerHTML += `<li>${projectTypeItem}</li>`;
                        });
                    }
                } else if (storeRenderedProjectType != null) {
                    storeRenderedProjectType.remove();
                }

                // Add href sheet.
                const storeRenderedSheet = storeRendered.querySelector(
                    ".type__buttons a.sheet"
                ) as HTMLElement;
                storeRenderedSheet.setAttribute("href", marker?.store?.url);

                // Add href appointment.
                const storeRenderedAppointment = storeRendered.querySelector(
                    ".type__buttons a.appointment"
                ) as HTMLElement;
                const bundle =
                    marker?.store?.bundle == "artisan"
                        ? "Artisan"
                        : "Salle d'expo";
                const coop =
                    marker?.store?.coop != undefined
                        ? marker?.store?.coop
                        : "na";
                storeRenderedAppointment.setAttribute(
                    "data-title",
                    marker?.store?.title
                );
                storeRenderedAppointment.setAttribute("data-bundle", bundle);
                storeRenderedAppointment.setAttribute("data-coop", coop);
                storeRenderedAppointment.setAttribute(
                    "href",
                    appointmentUrl + "&recipient=" + marker?.store?.id
                );

                // Add logo.
                const storeRenderedLogo = storeRendered.querySelector(
                    ".card__logo"
                ) as HTMLElement;
                if (marker?.store?.logo !== false) {
                    storeRenderedLogo.innerHTML = marker?.store?.logo;
                }

                storeLocatorContainer.append(storeRendered);
            }
        });
    }

    /*
     * Get appointment url.
     */
    function getAppointmentUrl() {
        // Appointment url for card.
        const appointmentUrlElement = document.querySelector(
            "[data-appointment-url]"
        ) as HTMLElement;
        const appointmentUrl = appointmentUrlElement.dataset?.appointmentUrl;

        // Get search params.
        const searchParams = new URLSearchParams(window.location.search);

        // Change coordinates format.
        const lat = searchParams.get(
            "coordinates[source_configuration][origin][lat]"
        );
        searchParams.delete("coordinates[source_configuration][origin][lat]");

        const lon = searchParams.get(
            "coordinates[source_configuration][origin][lon]"
        );
        searchParams.delete("coordinates[source_configuration][origin][lon]");

        const coordinates = `&latitude=${lat}&longitude=${lon}`;

        const url =
            appointmentUrl + "?" + searchParams.toString() + coordinates;
        return decodeURI(url);
    }

    /*
     * Clear cards list.
     */
    function clearCardsList() {
        // list desappearance
        const storeLocatorContainer = document.querySelector(
            ".store-locator__container"
        ) as HTMLElement;
        storeLocatorContainer.classList.remove("active");
        const storeLocatorListContent = document.querySelector(
            ".store-locator__list .views-content"
        ) as HTMLElement;
        storeLocatorListContent.innerHTML = "";
    }

    /*
     * Manage the card list header.
     */
    function manageCardListHeader(markers: any) {
        let type = document.querySelectorAll(
            '.filters--store-locator input[name="type[]"]'
        ) as NodeListOf<Element>;
        let output = "" as string;
        let numberArtisan = 0 as number;
        let numberExhibitionHall = 0 as number;

        if (markers.length == 0) {
            const list = document.querySelector(".store-locator__list");
            if (list != null) list.classList.remove("active");
            return;
        }

        if (type.length == 1) {
            let totalMarker = 0;
            markers.forEach((marker: any) => {
                if (marker?.store != undefined) {
                    totalMarker++;
                }
            });

            output =
                totalMarker > 1
                    ? `${totalMarker} résultats`
                    : `${totalMarker} résultat`;

            const totalStore = document.querySelector(
                ".store-locator__head .total"
            ) as HTMLElement;

            // @ts-ignore
            totalStore.innerHTML = output;
        } else {
            for (const marker of markers as any) {
                if (marker?.store?.bundle == "artisan") {
                    numberArtisan++;
                } else if (marker?.store?.bundle == "exhibition_hall") {
                    numberExhibitionHall++;
                }
            }

            const outputTabArtisans = `Artisans (${numberArtisan})`;
            const tabArtisans = document.querySelector(
                ".store-locator__head .tabs .type-artisan"
            ) as HTMLElement;
            // @ts-ignore
            tabArtisans.innerHTML = outputTabArtisans;

            const outputTabsExhibitionHall = `Salles d'exposition (${numberExhibitionHall})`;
            const tabsExhibitionHall = document.querySelector(
                ".store-locator__head .tabs .type-exhibition_hall"
            ) as HTMLElement;
            // @ts-ignore
            tabsExhibitionHall.innerHTML = outputTabsExhibitionHall;

            // Management of card list tabs.
            manageTab(tabArtisans, tabsExhibitionHall, numberArtisan);
        }
    }

    /*
     * Management of card list tabs.
     */
    function manageTab(
        tabArtisans: any,
        tabsExhibitionHall: any,
        numberArtisan: any
    ) {
        // Click event on tab.
        const tabs = document.querySelectorAll(
            ".tabs span"
        ) as NodeListOf<Element>;
        tabs.forEach(function (tab: any) {
            tab.classList.remove("active");

            tab.addEventListener("click", () => {
                // Tabs.
                tabs.forEach(function (tab: any) {
                    tab.classList.remove("active");
                });
                tab.classList.add("active");

                // Cards.
                document
                    .querySelectorAll(".type-card")
                    .forEach(function (card) {
                        card.classList.add("hidden");
                    });
                const classType = tab.dataset.type;
                document
                    .querySelectorAll(`.type-card.${classType}`)
                    .forEach(function (el) {
                        el.classList.remove("hidden");
                    });
            });
        });

        document.querySelectorAll(".type-card").forEach(function (el) {
            el.classList.add("hidden");
        });

        // Get search params.
        const searchParams = new URLSearchParams(window.location.search);

        // Change coordinates format.
        const status = searchParams.get("status");
        if (status != "" && status !== null) {
            if (status == "artisan") {
                activationTab("artisan", tabArtisans, tabsExhibitionHall);
            } else if (status == "exhibition_hall") {
                activationTab(
                    "exhibition_hall",
                    tabArtisans,
                    tabsExhibitionHall
                );
            }
        } else {
            // Add classes by default.
            if (numberArtisan == 0) {
                activationTab(
                    "exhibition_hall",
                    tabArtisans,
                    tabsExhibitionHall
                );
            } else {
                activationTab("artisan", tabArtisans, tabsExhibitionHall);
            }
        }
    }

    /*
     * Activation current tab.
     */
    function activationTab(
        tabActive: any,
        tabArtisans: any,
        tabsExhibitionHall: any
    ) {
        if (tabActive == "artisan") {
            tabsExhibitionHall.classList.remove("active");
            tabArtisans.classList.add("active");

            document
                .querySelectorAll(".type-card.type-artisan")
                .forEach(function (el) {
                    el.classList.remove("hidden");
                });
        } else if (tabActive == "exhibition_hall") {
            tabsExhibitionHall.classList.add("active");
            tabArtisans.classList.remove("active");

            document
                .querySelectorAll(".type-card.type-exhibition_hall")
                .forEach(function (el) {
                    el.classList.remove("hidden");
                });
        }
    }

    /*
     * Create markers.
     */
    function createMarkers(
        map: any,
        markers: any[],
        bounds: google.maps.LatLngBounds,
        stores: any[]
    ) {
        if (stores != undefined && stores.length > 0) {
            for (const [key, store] of Object.entries(stores)) {
                // Create marker.
                createMarker(store, map, markers, bounds);
            }

            // Add MarkerClusterer.
            createMarkerClusterer(map, markers);

            // Manage the card list header.
            manageCardListHeader(markers);
        }
    }

    /*
     * Create map.
     */
    function createMap() {
        // Create an array of styles.
        const mapStyles = [
            {
                featureType: "administrative",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#1E1E1E",
                    },
                ],
            },
            {
                featureType: "administrative",
                elementType: "geometry.fill",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "landscape",
                elementType: "all",
                stylers: [
                    {
                        color: "#E4E5E5",
                    },
                ],
            },
            {
                featureType: "landscape.man_made",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#828282",
                    },
                    {
                        lightness: "36",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "all",
                stylers: [
                    {
                        visibility: "simplified",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "all",
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 45,
                    },
                ],
            },

            {
                featureType: "water",
                elementType: "all",
                stylers: [
                    {
                        color: "#C3C4C6",
                    },
                    {
                        visibility: "on",
                    },
                ],
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            { featureType: "transit", stylers: [{ visibility: "off" }] },
        ];

        const center: google.maps.LatLngLiteral = {
            lat: 46.71109,
            lng: 1.7191036,
        };
        const mapOptions = {
            center,
            zoom: 6,
            styles: mapStyles,
            mapTypeControl: false,
        };

        return new google.maps.Map(
            document.getElementById("store-locator-map") as HTMLElement,
            mapOptions
        );
    }

    /*
     * Create marker.
     */
    function createMarker(store: any, map: any, markers: any, bounds: any) {
        if (store.coordinates) {
            const position = {
                lat: parseFloat(store.coordinates[0]),
                lng: parseFloat(store.coordinates[1]),
            };

            // Paramètre de icons
            let svg = "";
            if (store.bundle == "artisan") {
                svg = window.btoa(`
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.75 24C12.5984 24 12.4508 23.9748 12.3073 23.9243C12.1637 23.8739 12.0331 23.8006 11.9153 23.7045C8.91855 21.0266 6.68347 18.5413 5.21008 16.2486C3.73669 13.956 3 11.8502 3 9.93152C3 6.9045 3.98145 4.49249 5.94435 2.69548C7.90725 0.898493 10.1758 0 12.75 0C15.3242 0 17.5927 0.898493 19.5556 2.69548C21.5185 4.49249 22.5 6.9045 22.5 9.93152C22.5 11.8502 21.7633 13.956 20.2899 16.2486C18.8165 18.5413 16.5814 21.0266 13.5847 23.7045C13.475 23.8006 13.3464 23.8739 13.1988 23.9243C13.0512 23.9748 12.9016 24 12.75 24Z" fill="#C7352D"/>
                    <path d="M15.7953 14.2576C14.8825 14.7525 13.8674 15 12.75 15C11.6326 15 10.6175 14.7525 9.70468 14.2576C8.79189 13.7627 8.057 13.1075 7.5 12.2919C8.23021 11.7661 9.04068 11.3654 9.93139 11.0898C10.8221 10.8143 11.7616 10.6765 12.75 10.6765C13.7384 10.6765 14.6779 10.8143 15.5686 11.0898C16.4593 11.3654 17.2698 11.7661 18 12.2919C17.443 13.1075 16.7081 13.7627 15.7953 14.2576Z" fill="#FDFEFF"/>
                    <path d="M14.3917 8.19278C13.9409 8.61326 13.3936 8.82349 12.75 8.82349C12.1064 8.82349 11.5591 8.61326 11.1083 8.19278C10.6574 7.77232 10.432 7.26198 10.432 6.66175C10.432 6.06151 10.6574 5.55117 11.1083 5.13071C11.5591 4.71024 12.1064 4.5 12.75 4.5C13.3936 4.5 13.9409 4.71024 14.3917 5.13071C14.8426 5.55117 15.068 6.06151 15.068 6.66175C15.068 7.26198 14.8426 7.77232 14.3917 8.19278Z" fill="#FDFEFF"/>
                    </svg>
                    `);
            } else if (store.bundle == "exhibition_hall") {
                svg = window.btoa(`
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.75 24C12.5984 24 12.4508 23.9748 12.3073 23.9243C12.1637 23.8739 12.0331 23.8006 11.9153 23.7045C8.91855 21.0266 6.68347 18.5413 5.21008 16.2486C3.73669 13.956 3 11.8502 3 9.93152C3 6.9045 3.98145 4.49249 5.94435 2.69548C7.90725 0.898493 10.1758 0 12.75 0C15.3242 0 17.5927 0.898493 19.5556 2.69548C21.5185 4.49249 22.5 6.9045 22.5 9.93152C22.5 11.8502 21.7633 13.956 20.2899 16.2486C18.8165 18.5413 16.5814 21.0266 13.5847 23.7045C13.475 23.8006 13.3464 23.8739 13.1988 23.9243C13.0512 23.9748 12.9016 24 12.75 24Z" fill="#003160"/>
                    <path d="M8.50453 15C8.19563 15 7.93417 14.895 7.72013 14.685C7.50609 14.475 7.39907 14.2185 7.39907 13.9154V9.12231C7.15289 8.92384 6.96805 8.66634 6.84456 8.34981C6.72108 8.03327 6.71853 7.69153 6.83692 7.32461L7.45552 5.3423C7.53705 5.09 7.67544 4.88654 7.87066 4.73192C8.06588 4.57731 8.29913 4.5 8.57039 4.5H16.9179C17.1891 4.5 17.4208 4.57385 17.6129 4.72154C17.805 4.86923 17.9449 5.0723 18.0327 5.33076L18.6631 7.32461C18.7815 7.69153 18.7789 8.03211 18.6554 8.34634C18.532 8.66057 18.3471 8.92307 18.1009 9.13384V13.9154C18.1009 14.2185 17.9939 14.475 17.7799 14.685C17.5658 14.895 17.3044 15 16.9955 15H8.50453ZM14.0954 8.70001C14.4294 8.70001 14.6805 8.59982 14.8486 8.39943C15.0168 8.19905 15.0856 7.98386 15.055 7.75385L14.6834 5.39997H13.2087V7.76999C13.2087 8.02231 13.2957 8.24039 13.4697 8.42424C13.6438 8.60809 13.8523 8.70001 14.0954 8.70001ZM11.3435 8.70001C11.6249 8.70001 11.8533 8.60809 12.0285 8.42424C12.2038 8.24039 12.2914 8.02231 12.2914 7.76999V5.39997H10.8166L10.445 7.77692C10.412 7.99 10.4803 8.19712 10.6496 8.39827C10.819 8.59943 11.0502 8.70001 11.3435 8.70001ZM8.62213 8.70001C8.84871 8.70001 9.04355 8.62251 9.20664 8.46751C9.36971 8.3125 9.47046 8.11769 9.50887 7.88308L9.86875 5.39997H8.57039C8.50375 5.39997 8.45082 5.4144 8.41162 5.44325C8.37241 5.4721 8.34301 5.51537 8.32341 5.57307L7.7354 7.52537C7.65464 7.78307 7.69266 8.04327 7.84947 8.30596C8.00628 8.56866 8.26383 8.70001 8.62213 8.70001ZM16.8779 8.70001C17.2087 8.70001 17.462 8.57251 17.6376 8.31751C17.8132 8.0625 17.8556 7.79845 17.7646 7.52537L17.146 5.56152C17.1264 5.50383 17.097 5.46248 17.0578 5.43747C17.0186 5.41247 16.9657 5.39997 16.899 5.39997H15.6313L15.9911 7.88308C16.0296 8.11769 16.1303 8.3125 16.2934 8.46751C16.4565 8.62251 16.6513 8.70001 16.8779 8.70001Z" fill="#FDFEFF"/>
                    </svg>
                    `);
            }

            let markerIcon = {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(42, 42),
                origin: new google.maps.Point(0, 0),
            };

            // Create marker.
            let marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(position),
                icon: markerIcon,
            });

            // Set store information.
            marker.set("store", store);

            // Click on marker.
            google.maps.event.addListener(marker, "click", function () {
                map.setZoom(14);
                map.setCenter(marker.getPosition());

                markersInViewport(map, [marker]);
                // renderCards([marker]);

                // @ts-ignore
                const storeId = marker?.store?.id;

                if (!storeId) return;

                const storeIntoList = document.querySelector(
                    `.store-locator__list .card[data-id="${storeId}"]`
                ) as HTMLElement;

                const storeLocatorElement = document.querySelector(
                    ".store-locator"
                ) as HTMLElement;

                if (
                    !storeIntoList ||
                    storeIntoList.classList.contains("hidden") ||
                    !storeIntoList.parentNode ||
                    !storeLocatorElement
                )
                    return;

                document.documentElement.scrollTop =
                    storeLocatorElement.offsetTop - 80;

                (storeIntoList.parentNode as HTMLElement).scrollTop =
                    storeIntoList.offsetTop -
                    (storeIntoList.parentNode as HTMLElement).offsetTop;
            });

            // All markers.
            bounds.extend(position);
            markers.push(marker);
        }

        return markers;
    }

    /*
     * Create user marker.
     */
    function createUserMarker(map: any, markers: any, bounds: any) {
        const coordinatesLatInput = document.querySelector(
            `input[name="coordinates[source_configuration][origin][lat]"]`
        ) as any;
        const coordinatesLngInput = document.querySelector(
            `input[name="coordinates[source_configuration][origin][lon]"]`
        ) as any;

        if (
            coordinatesLatInput?.value != "" &&
            coordinatesLngInput?.value != ""
        ) {
            // Zoom on the map if there is a search by location.
            const lat = Number(coordinatesLatInput?.value);
            const lng = Number(coordinatesLngInput?.value);
            map.setCenter(
                new google.maps.LatLng({
                    lat: lat,
                    lng: lng,
                })
            );

            map.setZoom(10);

            if (window.innerHeight < 775) {
                map.setZoom(9);
            }

            let svg = window.btoa(`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4063_7764)">
                        <circle cx="12" cy="12" r="12" fill="#69779A" fill-opacity="0.4"/>
                        <circle cx="12" cy="12" r="5.45455" fill="#003160" stroke="#FDFEFF" stroke-width="1.45455"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_4063_7764">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>`);

            let markerIcon = {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(75, 35),
                anchor: new google.maps.Point(25, 34),
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(29, 13),
            };

            const position = { lat: lat, lng: lng };
            const markerUser = new google.maps.Marker({
                map: map,
                position: position,
                zIndex: 99999,
                icon: markerIcon,
                label: {
                    text: " ",
                    color: "#fff",
                    fontSize: "10px",
                    fontWeight: "bold",
                },
            });

            // Set id.
            markerUser.set("bundle", "user");

            bounds.extend(position);
            markers.push(markerUser);
        }
    }

    /*
     * Create MarkerClusterer.
     */
    function createMarkerClusterer(map: any, markers: any) {
        const svg = window.btoa(`
                    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="250" height="250" rx="125" fill="white"/>
                    </svg>`);

        // Render MarkerClusterer.
        const renderer = {
            // @ts-ignore
            render: ({ count, position }) =>
                new google.maps.Marker({
                    label: {
                        text: String(count),
                        color: "#003160",
                        fontSize: "18px",
                        fontWeight: "700",
                    },
                    icon: {
                        url: `data:image/svg+xml;base64,${svg}`,
                        scaledSize: new google.maps.Size(50, 50),
                    },
                    position,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                }),
        };

        // Param MarkerClusterer zone.
        const algorithm = new GridAlgorithm({
            gridSize: 55,
        });

        // Click on the cluster.
        const onClusterClick = function (_event: any, cluster: any, map: any) {
            if (cluster.markers.length > 2) {
                map.setZoom(map.getZoom() + 1);
            }
            else if (cluster.markers.length == 2) {
                let latlngbounds = new google.maps.LatLngBounds();
                cluster.markers.forEach((marker: any) => {
                    latlngbounds.extend(marker.position);
                });
                map.setCenter(latlngbounds.getCenter());
                map.fitBounds(latlngbounds);
            } else {
                map.setZoom(14);
            }

            map.setCenter({
                lat: cluster.position.lat(),
                lng: cluster.position.lng(),
            });
        };

        new MarkerClusterer({
            map,
            markers,
            algorithm,
            renderer,
            onClusterClick,
        });
    }

    /**
     * AUTOCOMPLETE.
     * ****************************************
     */
    const StoreLocatorElement = document.getElementById(
        "store-locator"
    ) as HTMLElement;
    const geolocationButton = document.querySelector(
        'button[name="geolocation"]'
    ) as HTMLButtonElement;
    const searchField = document.querySelector(
        `input[name="location"]`
    ) as HTMLInputElement;
    const latitudeField = document.querySelector(
        `input[name="coordinates[source_configuration][origin][lat]"]`
    ) as HTMLInputElement;
    const longitudeField = document.querySelector(
        `input[name="coordinates[source_configuration][origin][lon]"]`
    ) as HTMLInputElement;
    const addressField = document.querySelector(
        `input[name="address"]`
    ) as HTMLInputElement;
    const postalCodeField = document.querySelector(
        `input[name="postal_code"]`
    ) as HTMLInputElement;
    const cityField = document.querySelector(
        `input[name="city"]`
    ) as HTMLInputElement;

    if (
        StoreLocatorElement &&
        geolocationButton &&
        searchField &&
        latitudeField &&
        longitudeField &&
        addressField &&
        postalCodeField &&
        cityField
    ) {
        PlaceSearch(
            geolocationButton,
            searchField,
            latitudeField,
            longitudeField,
            addressField,
            postalCodeField,
            cityField
        );
    }

    // Submit form.
    const form = document.getElementById(
        "store-locator-form"
    ) as HTMLFormElement;
    if (form) {
        form.addEventListener("submit", (e) => {
            const formItemLocation = document.querySelector(
                `.form-item-location`
            ) as HTMLElement;
            const coordinatesLatInput = document.querySelector(
                `input[name="coordinates[source_configuration][origin][lat]"]`
            ) as any;
            const coordinatesLngInput = document.querySelector(
                `input[name="coordinates[source_configuration][origin][lon]"]`
            ) as any;
            const title = document.querySelector(`input[name="title"]`) as any;
            const type_list = document.querySelectorAll(
                '.filters--store-locator input[name="type[]"]'
            ) as NodeListOf<Element>;
            const type_array = Array.prototype.slice.call(type_list);
            const type = type_list.length > 0 ? type_array[0]?.value : "";

            formItemLocation.classList.remove("error");

            let error = false;
            if (
                coordinatesLatInput?.value == "" &&
                coordinatesLngInput?.value == ""
            ) {
                error = true;
            }

            if (title?.value != "" && type == "artisan") {
                error = false;
            }

            if (error) {
                e.preventDefault();
                formItemLocation.classList.add("error");
            }

            form.action += "#store-locator";
        });
    }
}
